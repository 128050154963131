export interface WebsiteConfig {
  title: string;
  description: string;
  //coverImage: string;
  logo: string;
  rss: string;
  /**
   * Specifying a valid BCP 47 language helps screen readers announce text properly.
   * See: https://dequeuniversity.com/rules/axe/2.2/valid-lang
   */
  lang: string;
  /**
   * blog full path, no ending slash!
   */
  siteUrl: string;
  facebook?: string;
  twitter?: string;
  linkedin?: string;
  instagram?: string;
  /**
   * hide or show all email subscribe boxes
   */
  showSubscribe: boolean;
  /**
   * create a list on mailchimp and then create an embeddable signup form. this is the form action
   */
  mailchimpAction?: string;
  /**
   * this is the hidden input field name
   */
  mailchimpName?: string;
}

const config: WebsiteConfig = {
  title: "Chris Capasso's Collaboration",
  description: '',
  //coverImage: 'img/blog-cover.jpg',
  logo: 'img/logo.png',
  lang: 'en',
  siteUrl: 'https://www.collabchris.com',
  facebook: 'https://www.facebook.com/ccapasso01',
  twitter: 'https://twitter.com/ccapasso',
  linkedin: "https://www.linkedin.com/in/chris-capasso-74189136/",
  instagram: "https://www.instagram.com/ccapasso01",
  rss: '/rss.xml',
  showSubscribe: false,
  //mailchimpAction: 'https://twitter.us19.list-manage.com/subscribe/post?u=a89b6987ac248c81b0b7f3a0f&amp;id=7d777b7d75',
  //mailchimpName: 'b_a89b6987ac248c81b0b7f3a0f_7d777b7d75', 
};

export default config;
